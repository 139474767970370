import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Grid,
  Card,
  CardContent,
  CardMedia,
  FormControlLabel,
  List,
  ListItem,
  TextField,
  Skeleton,
  Typography,
  Slider,
  Box,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  LocalMovies as LocalMoviesIcon,
  ReceiptLong as ReceiptIcon
} from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import { StyledDialog, StyledMenu, StyledMenuItem } from "./ActionMenuRoot";
import MDButton from "components/MDButton";
import { getPremiumMovies, setPremiumMovies } from "api/customers";
import MDSnackbar from "components/MDSnackbar";
import EditDialog from "components/Dialogs/EditDialog";
import PremiumsDialog from "components/Dialogs/PremiumsDialog";
import TransactionsDialog from "components/Dialogs/TransactionsDialog";

const ActionMenu = ({ customerInfo, refetchData }) => {
  // States for frontend modals
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMovieDialog, setOpenMovieDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openPremiumEditDialog, setOpenPremiumEditDialog] = useState(false);
  const [openTransactionsDialog, setOpenTransactionsDialog] = useState(false);
  // States for maintaining data
  const [userInfo, setUserInfo] = useState(localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null);
  const [startingNumber, setStartingNumber] = useState(
    customerInfo.currentSession.current_index + 4
  );
  const [selectedMovies, setSelectedMovies] = useState([]);
  const [movies, setMovies] = useState([]);
  const [movieLoading, setMovieLoading] = useState(false);
  const { currentSession, agent, tier, ...rest } = customerInfo;
  const { tier_name } = tier;
  const filteredCustomerInfo = { ...rest, tier_name };
  const actionMenuItems = [
    { name: "Edit Customer Details", icon: <EditIcon fontSize="small" /> },
    { name: "Assign Premium Movies", icon: <LocalMoviesIcon fontSize="small" /> },
    { name: "Edit Premium Movies", icon: <VisibilityIcon fontSize="small" /> },
    { name: "Show Transaction History", icon: <ReceiptIcon fontSize="small" /> },
  ];
  const filteredActionMenuItems = userInfo.role === "agent"
  ? actionMenuItems.slice(0, -2) // Exclude the last two items
  : actionMenuItems;
  // States for notification bars
  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    color: "success",
    content: "Notification",
    dateTime: "Just Now",
    icon: "check",
  });
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [filteredMovies, setFilteredMovies] = useState([]);

  useEffect(() => {
    if (movies.length > 0) {
      const maxPrice = Math.max(
        ...movies.map((movie) => parseFloat(movie.movie_price))
      );
      setPriceRange([0, maxPrice]);
    }
  }, [movies]);

  useEffect(() => {
    const filtered = movies.filter(
      (movie) =>
        parseFloat(movie.movie_price) >= priceRange[0] &&
        parseFloat(movie.movie_price) <= priceRange[1]
    );
    setFilteredMovies(filtered);
  }, [movies, priceRange]);

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
  };
  // Update movie orders when starting number changes
  useEffect(() => {
    setSelectedMovies((prevMovies) =>
      prevMovies.map((movie, index) => ({
        ...movie,
        order: startingNumber + index,
      }))
    );
  }, [startingNumber]);

  // Fetch movies when the dialog is opened
  useEffect(() => {
    if (openMovieDialog) {
      setMovieLoading(true);

      getPremiumMovies(customerInfo.customer_id)
        .then(fetchedMovies => setMovies(fetchedMovies.data))
        .catch(error => console.error("Failed to fetch movies:", error))
        .finally(() => setMovieLoading(false));
    }
  }, [openMovieDialog]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    if (action === "Assign Premium Movies")
      setOpenMovieDialog(true);
    else if (action === "Edit Customer Details")
      setOpenEditDialog(true);
    else if (action === "Edit Premium Movies")
      setOpenPremiumEditDialog(true);
    else if (action === "Show Transaction History")
      setOpenTransactionsDialog(true);

    handleClose();
  };

  const handleMovieToggle = (movie) => {
    setSelectedMovies((prev) => {
      const isSelected = prev.some((m) => m.movie_id === movie.movie_id);
      if (isSelected) {
        return prev.filter((m) => m.movie_id !== movie.movie_id);
      } else {
        return [...prev, { ...movie, order: prev.length + 1 }];
      }
    });
  };

  const handleStartingNumberChange = (event) => {
    const newStartingNumber = parseInt(event.target.value, 10);
    setStartingNumber(newStartingNumber);
  };

  const handleSubmitMovies = () => {
    setOpenMovieDialog(false);
    setOpenConfirmationDialog(true);
  };

  const handleConfirmAssignment = async () => {
    console.log("Selected movies with order:", selectedMovies);
    const movieIds = selectedMovies.map((movie) => movie.movie_id);
    const response = await setPremiumMovies(
      customerInfo.customer_id,
      startingNumber,
      movieIds
    );
    if (response.status === 201) {
      setNotificationContent({
        color: "success",
        content: "Movies has been set for the user.",
        dateTime: "Just Now",
        icon: "check",
      });
    } else {
      setNotificationContent({
        color: "error",
        content: response.data.message,
        dateTime: "Just Now",
        icon: "warning",
      });
    }

    setShowNotification(true);
    setOpenConfirmationDialog(false);
    setSelectedMovies([]);
    setStartingNumber(1);
  };

  return (
    <>
      <MDTypography component={IconButton} onClick={handleClick}>
        <MoreVertIcon />
      </MDTypography>

      <MDSnackbar
        color={notificationContent.color}
        icon={notificationContent.icon}
        title="H.M. Agent Portal"
        content={notificationContent.content}
        dateTime={notificationContent.dateTime}
        open={showNotification}
        onClose={() => setShowNotification(false)}
        close={() => setShowNotification(false)}
        bgWhite
      />

      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {filteredActionMenuItems.map((actionItem, index) => (
          <StyledMenuItem
            key={index}
            onClick={() => handleAction(actionItem.name)}
          >
            <MDTypography component={ListItemIcon}>
              {actionItem.icon}
            </MDTypography>
            <MDTypography component={ListItemText}>
              {actionItem.name}
            </MDTypography>
          </StyledMenuItem>
        ))}
      </StyledMenu>

      <Dialog
        open={openMovieDialog}
        onClose={() => setOpenMovieDialog(false)}
        aria-labelledby="assign-movies-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="assign-movies-dialog-title">
          Assign Movies to {customerInfo.username}
        </DialogTitle>
        <DialogContent>
          <Box component="section" sx={{ paddingLeft: "10%", paddingRight: "30%" }} marginBottom={5}>
            <Typography gutterBottom>Filter by Price Range</Typography>
            <Slider
              value={priceRange}
              onChange={handlePriceRangeChange}
              valueLabelDisplay="auto"
              min={0}
              max={Math.max(
                ...movies.map((movie) => parseFloat(movie.movie_price))
              )}
              step={0.01}
            />
            <Typography gutterBottom>
              Price: ${priceRange[0].toFixed(2)} - ${priceRange[1].toFixed(2)}
            </Typography>
          </Box>
          <Typography variant="h5" gutterBottom>
            Movies
          </Typography>
          <Grid container spacing={2}>
            {movieLoading && filteredMovies.length > 0
              ? // Display skeleton loaders while movies are being fetched
              Array.from(new Array(6)).map((_, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={140}
                  />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" width="60%" />
                </Grid>
              ))
              : filteredMovies.map((movie) => (
                <Grid item xs={12} sm={6} md={4} key={movie.movie_id}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="140"
                      image={movie.movie_image}
                      alt={movie.movie_name}
                    />
                    <CardContent>
                      <MDTypography gutterBottom variant="h6" component="div">
                        {movie.movie_name}
                      </MDTypography>
                      <MDTypography variant="body2" sx={{ mb: 1 }}>
                        {movie.movie_description}
                      </MDTypography>
                      <MDTypography variant="body1" sx={{ mb: 1 }}>
                        ${parseFloat(movie.movie_price).toFixed(2)}
                      </MDTypography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedMovies.some(
                              (m) => m.movie_id === movie.movie_id
                            )}
                            onChange={() => handleMovieToggle(movie)}
                          />
                        }
                        label="Select"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpenMovieDialog(false)}>Cancel</MDButton>
          <MDButton
            onClick={handleSubmitMovies}
            variant="contained"
            color="info"
            disabled={selectedMovies.length === 0}
          >
            Assign Selected Movies
          </MDButton>
        </DialogActions>
      </Dialog>

      <StyledDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        aria-labelledby="confirmation-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="confirmation-dialog-title">
          Confirm Movie Assignment
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
            <div style={{ display: "flex", gap: "8px" }}>
              <MDTypography variant="body2" gutterBottom display="inline">
                Username:
              </MDTypography>
              <MDTypography
                variant="body2"
                color="info"
                fontWeight="bold"
                display="inline"
              >
                {customerInfo.username}
              </MDTypography>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <MDTypography variant="body2" gutterBottom display="inline">
                Total Limit:
              </MDTypography>
              <MDTypography
                variant="body2"
                color="info"
                fontWeight="bold"
                display="inline"
              >
                {customerInfo.tier.tier_limit}
              </MDTypography>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <MDTypography variant="body2" gutterBottom display="inline">
                Current Movie Number:
              </MDTypography>
              <MDTypography
                variant="body2"
                color="info"
                fontWeight="bold"
                display="inline"
              >
                {customerInfo.currentSession.current_index}
              </MDTypography>
            </div>
          </div>

          <MDTypography variant="body1" gutterBottom>
            Please confirm the starting order number for the selected movie(s)
          </MDTypography>
          <MDTypography
            component="div"
            variant="caption"
            color="text"
            fontWeight="light"
          >
            Select starting number 3 to 4 number above the current order number
            for expected results
          </MDTypography>

          {(startingNumber <= customerInfo.currentSession.current_index ||
            startingNumber + (selectedMovies.length - 1) >
            customerInfo.tier.tier_limit) && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                The movie range must fit between the current and total
                tier limit.
              </MDTypography>
            )}

          <TextField
            type="number"
            label="Starting Number"
            value={startingNumber}
            onChange={handleStartingNumberChange}
            inputProps={{ min: 1 }}
            fullWidth
            margin="normal"
          />
          <List>
            {selectedMovies.map((movie, index) => (
              <ListItem key={movie.movie_id}>
                <MDTypography variant="body2" sx={{ mr: 2, minWidth: 30 }}>
                  {startingNumber + index}.
                </MDTypography>
                <MDTypography>{movie.movie_name}</MDTypography>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirmationDialog(false)}>
            Cancel
          </MDButton>
          <MDButton
            onClick={handleConfirmAssignment}
            variant="contained"
            color="info"
            disabled={
              !startingNumber ||
              startingNumber <= customerInfo.currentSession.current_index ||
              startingNumber + (selectedMovies.length - 1) >
              customerInfo.tier.tier_limit
            }
          >
            Confirm Assignment
          </MDButton>
        </DialogActions>
      </StyledDialog>

      <EditDialog
        data={filteredCustomerInfo}
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        setShowNotification={setShowNotification}
        setNotificationContent={setNotificationContent}
        refetchData={refetchData}
      />

      <PremiumsDialog
        customerInfo={customerInfo}
        openPremiumDialog={openPremiumEditDialog}
        setOpenPremiumDialog={setOpenPremiumEditDialog}
        setShowNotification={setShowNotification}
        setNotificationContent={setNotificationContent}
      />

      <TransactionsDialog
        customer_id={customerInfo.customer_id}
        openTransactionsDialog={openTransactionsDialog}
        setOpenTransactionsDialog={setOpenTransactionsDialog}
      />
    </>
  );
};

// Add PropTypes validation
ActionMenu.propTypes = {
  customerInfo: PropTypes.shape({
    customer_id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    agent: PropTypes.shape({
      agent_id: PropTypes.number.isRequired,
    }).isRequired,
    currentSession: PropTypes.shape({
      current_index: PropTypes.number.isRequired,
    }).isRequired,
    tier: PropTypes.shape({
      tier_limit: PropTypes.number.isRequired,
      tier_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  refetchData: PropTypes.func.isRequired,
};

export default ActionMenu;
